// import { Checkbox, Modal, notification } from "antd"
// import { useEffect, useState } from "react"
// import { useSelector } from "react-redux"
// import { fetchUserPlaylist, getListAudios, setListAudios } from "../../../../redux/audioSlice"
// import MusicIcon from "../../../../assets/images/project/music-icon.svg"
// import NowPlayingIcon from "../../../../assets/images/project/now-playing-icon.svg"
// import DeleteMusicIcon from "../../../../assets/images/project/delete-music-icon.svg"
// import "./styles.scss"
// import { MUSIC_AND_AUDIO_SOURCE } from "../../../../utils/constants"
// import { useAppDispatch } from "../../../../redux"
// import PlayIcon from "../../../../assets/images/spotify/play.png"
// import PauseIcon from "../../../../assets/images/spotify/pause.png"
// import { getShouldShufflePlaylist, setMusicAndAudioSource, setShouldShufflePlaylist } from "../../../../redux/appSlice"
// import { getIsMusicPaused, setMusicIsPaused } from "../../../../redux/spotifySlice"
// import _ from "lodash"
// import ModalUploadedMedia from "../../../drawerObjectDetail/components/modalUploadedMedia/ModalUploadedMedia"
// import userAudioApi from "../../../../api/userAudio.api"

// const YourAudioTab = () => {
//     const dispatch = useAppDispatch()
//     const [isShowModalUploadMusic, setIsShowModalUploadMusic] = useState(false)
//     const listAudios = useSelector(getListAudios)
//     const musicIsPaused = useSelector(getIsMusicPaused)
//     const shouldShufflePlaylist = useSelector(getShouldShufflePlaylist)

//     const onReloadListMusic = () => {
//         dispatch(fetchUserPlaylist())
//     }

//     useEffect(() => {
//         console.log(listAudios, "LIST MUSICS")
//     }, [listAudios])

//     const onDeleteRecord = (record) => {
//         Modal.confirm({
//             title: "Are you sure to delete this audio?",
//             centered: true,
//             className: "dialog-confirm",
//             onOk: () => {
//                 userAudioApi.deleteUserAudio(record.id).then(() => {
//                     notification.success({
//                         message: "Removed audio successfully!"
//                     })
//                     onReloadListMusic()
//                 }).catch(err => {
//                     notification.error({
//                         message: _.get(err, ['response', 'data', 'message'], `Can't remove audio from playlist!`)
//                     })
//                 })
//             }
//         })
//     }

//     const onPlayYourPlaylist = () => {
//         dispatch(setMusicAndAudioSource(MUSIC_AND_AUDIO_SOURCE.YOUR_AUDIO))
//         dispatch(setMusicIsPaused(!musicIsPaused))
//     }

//     const onPlaySelectedAudio = (selectedAudio) => {
//         const updatedList = listAudios.map((audio) => ({
//             ...audio,
//             isPlaying: audio.id === selectedAudio.id, // Only the clicked song will play
//         }))
//         dispatch(setListAudios(updatedList))
//         dispatch(setMusicIsPaused(false)) // Ensure the selected audio starts playing
//     }

//     const onAddAudio = (media) => {
//         console.log(media, 'MEDIA ID')
//         userAudioApi.addUserAudio({ asset: media.id }).then(() => {
//             notification.success({
//                 message: "Added audio successfully!"
//             })
//             onReloadListMusic()
//         }).catch(err => {
//             notification.error({
//                 message: _.get(err, ['response', 'data', 'message'], `Can't add audio to playlist!`)
//             })
//         })

//         setIsShowModalUploadMusic(false)
//     }

//     return <>
//         <div className="px-[52px] pb-[0px] pt-[40px] flex-auto h-[0px] flex flex-col your-audio-tab">
//             <div className="your-audio-tab-header pb-[10px]">
//                 <div className="text-title">
//                  Play music and voiceovers
//                 </div>
//                 <div className="flex gap-[40px]">
//                     <Checkbox
//                         className="autoplay-checkbox shared-checkbox"
//                         checked={shouldShufflePlaylist}
//                         onChange={(e) => { dispatch(setShouldShufflePlaylist(e.target.checked)) }}
//                     >
//                         <span className="checkbox-content">Shuffle</span>
//                     </Checkbox>
//                     <button className="btn-add-media" onClick={() => { setIsShowModalUploadMusic(true) }}>
//                         + Metadrob Playlist
//                     </button>
//                 </div>
//             </div>
//             <div className="your-audio-tab-content py-[12px] flex-auto overflow-y-auto">
//                 <div className="flex items-center gap-[30px] mb-[8px]">
//                     <div className="text-media-playlist">
//                         Media Playlist
//                     </div>
//                     {listAudios.length > 0 && <div className="play-button" onClick={() => { onPlayYourPlaylist() }}>
//                         <img src={musicIsPaused ? PlayIcon : PauseIcon} alt="" className="w-[30px] h-[30px]" />
//                         {musicIsPaused ? "Play" : "Pause"}
//                     </div>}
//                 </div>

//                 {listAudios.length > 0 && <div className="audio-item-container">
//                     {listAudios.map((el) => (
//                         <div
//                             className={`audio-item ${el.isPlaying ? 'playing' : ''}`}
//                             key={el.id}
//                             onClick={() => onPlaySelectedAudio(el)} // Play selected song on click
//                         >
//                             <div className="flex gap-[12px] items-center">
//                                 <img src={MusicIcon} alt="" />
//                                 <span className="audio-name">
//                                     {el.name}
//                                 </span>
//                             </div>
//                             <div className="flex gap-[18px] items-center">
//                                 {el.isPlaying && (
//                                     <>
//                                         <span className="whitespace-nowrap text-now-playing">Now playing</span>
//                                         <img src={NowPlayingIcon} alt="" />
//                                     </>
//                                 )}
//                                 <img
//                                     src={DeleteMusicIcon}
//                                     alt=""
//                                     className="cursor-pointer"
//                                     onClick={(e) => {
//                                         e.stopPropagation()
//                                         onDeleteRecord(el)
//                                     }}
//                                 />
//                             </div>
//                         </div>
//                     ))}
//                 </div>}
//             </div>
//         </div>

//         <ModalUploadedMedia
//             open={isShowModalUploadMusic}
//             onClose={() => { setIsShowModalUploadMusic(false) }}
//             filterExts=".mp3,.wav"
//             onSelectMedia={(media) => { onAddAudio(media) }}
//         />
//     </>
// }

// export default YourAudioTab



import { Checkbox, Modal, notification } from "antd"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { fetchUserPlaylist, getListAudios, setListAudios } from "../../../../redux/audioSlice"
import MusicIcon from "../../../../assets/images/project/music-icon.svg"
import NowPlayingIcon from "../../../../assets/images/project/now-playing-icon.svg"

import MuteIcon from "../../../../assets/images/project/mute_icon.png"

import DeleteMusicIcon from "../../../../assets/images/project/delete-music-icon.svg"
import "./styles.scss"
import { MUSIC_AND_AUDIO_SOURCE } from "../../../../utils/constants"
import { useAppDispatch } from "../../../../redux"
import PlayIcon from "../../../../assets/images/spotify/play.png"
import PauseIcon from "../../../../assets/images/spotify/pause.png"
import { getShouldShufflePlaylist, setMusicAndAudioSource, setShouldShufflePlaylist } from "../../../../redux/appSlice"
import { getIsMusicPaused, setMusicIsPaused } from "../../../../redux/spotifySlice"
import _ from "lodash"
import ModalUploadedMedia from "../../../drawerObjectDetail/components/modalUploadedMedia/ModalUploadedMedia"
import userAudioApi from "../../../../api/userAudio.api"

const YourAudioTab = () => {
    const dispatch = useAppDispatch()
    const [isShowModalUploadMusic, setIsShowModalUploadMusic] = useState(false)
    const [isMuted, setIsMuted] = useState(false) // State to track mute status
    const listAudios = useSelector(getListAudios)
    const musicIsPaused = useSelector(getIsMusicPaused)
    const shouldShufflePlaylist = useSelector(getShouldShufflePlaylist)
    const [selectedSong, setSelectedSong] = useState(null);
    const onReloadListMusic = () => {
        dispatch(fetchUserPlaylist())
    }

  

    const onDeleteRecord = (record) => {
        Modal.confirm({
            title: "Are you sure to delete this audio?",
            centered: true,
            className: "dialog-confirm",
            onOk: () => {
                userAudioApi.deleteUserAudio(record.id).then(() => {
                    notification.success({
                        message: "Removed audio successfully!"
                    })
                    onReloadListMusic()
                }).catch(err => {
                    notification.error({
                        message: _.get(err, ['response', 'data', 'message'], `Can't remove audio from playlist!`)
                    })
                })
            }
        })
    }

    const onPlayYourPlaylist = () => {
        dispatch(setMusicAndAudioSource(MUSIC_AND_AUDIO_SOURCE.YOUR_AUDIO))
        dispatch(setMusicIsPaused(!musicIsPaused))
    }

    const onPlaySelectedAudio = (selectedAudio) => {
        const updatedList = listAudios.map((audio) => ({
            ...audio,
            isPlaying: audio.id === selectedAudio.id, // Only the clicked song will play
        }))
        dispatch(setListAudios(updatedList))
        dispatch(setMusicIsPaused(false)) // Ensure the selected audio starts playing
    }

    const onMuteAudio = () => {
        setIsMuted(!isMuted) // Toggle mute state
        const audio = document.querySelector('audio') // Assuming you have an <audio> element for playback
        if (audio) {
            audio.muted = !audio.muted
        }
    }

    const onAddAudio = (media) => {
       
        userAudioApi.addUserAudio({ asset: media.id }).then(() => {
            notification.success({
                message: "Added audio successfully!"
            })
            onReloadListMusic()
        }).catch(err => {
            notification.error({
                message: _.get(err, ['response', 'data', 'message'], `Can't add audio to playlist!`)
            })
        })

        setIsShowModalUploadMusic(false)
    }

  

    const handleCheckboxChange = (el) => {
      
        
        if (selectedSong === el?.assetId?._id) {
            setSelectedSong(null); // Deselect song if it's already selected
        } else {
            setSelectedSong(el?.assetId?._id); // Select the song
        }
    };

    return <>
        <div className="px-[52px] pb-[0px] pt-[40px] flex-auto h-[0px] flex flex-col your-audio-tab">
            <div className="your-audio-tab-header pb-[10px]">
                <div className="text-title">
                 Play music and voiceovers
                </div>
                <div className="flex gap-[40px]">
                    <Checkbox
                        className="autoplay-checkbox shared-checkbox"
                        checked={shouldShufflePlaylist}
                        onChange={(e) => { dispatch(setShouldShufflePlaylist(e.target.checked)) }}
                    >
                        <span className="checkbox-content">Shuffle</span>
                    </Checkbox>
                    <button className="btn-add-media" onClick={() => { setIsShowModalUploadMusic(true) }}>
                        + Metadrob Playlist
                    </button>
                </div>
            </div>
            <div className="your-audio-tab-content py-[12px] flex-auto overflow-y-auto">
                <div className="flex items-center gap-[30px] mb-[8px]">
                    <div className="text-media-playlist">
                        Media Playlist
                    </div>
                    {listAudios.length > 0 && <div className="play-button" onClick={() => { onPlayYourPlaylist() }}>
                        <img src={musicIsPaused ? PlayIcon : PauseIcon} alt="" className="w-[30px] h-[30px]" />
                        {musicIsPaused ? "Play" : "Pause"}
                    </div>}
                </div>

                {listAudios.length > 0 && <div className="audio-item-container ">
                    {listAudios.map((el) => (
                        <div
                            className={`audio-item ${el.isPlaying ? 'playing' : ''}`}
                            key={el.id}
                            onClick={() => onPlaySelectedAudio(el)} // Play selected song on click
                        >
                            <div className="flex gap-[12px] items-center">
                                <img src={MusicIcon} alt="" />
                                <span className="audio-name">
                                    {el.name}
                                </span>
                            </div>
                            <div className="flex gap-[18px] items-center">
                                {el.isPlaying && (
                                    <>
                                        <span className="whitespace-nowrap text-now-playing">Now playing</span>
                                        <img
                                            src={isMuted ? MuteIcon : NowPlayingIcon} // Show MuteIcon when muted, else NowPlayingIcon
                                            alt=""
                                            onClick={(e) => { 
                                                e.stopPropagation() // Prevent triggering the parent click event
                                                onMuteAudio() // Mute or unmute the audio
                                            }}
                                            className="cursor-pointer"
                                        />
                                    </>
                                )}
                                <img
                                    src={DeleteMusicIcon}
                                    alt=""
                                    className="cursor-pointer"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        onDeleteRecord(el)
                                    }}
                                />
                              <input
                                    type="checkbox"
                                    checked={selectedSong === el?.assetId?._id} // Check if the song is selected
                                    onChange={() => handleCheckboxChange(el)} // Toggle selection
                                />
                            </div>
                        </div>
                    ))}
                </div>}
            </div>
        </div>

        <ModalUploadedMedia
            open={isShowModalUploadMusic}
            onClose={() => { setIsShowModalUploadMusic(false) }}
            filterExts=".mp3,.wav"
            onSelectMedia={(media) => { onAddAudio(media) }}
        />
    </>
}

export default YourAudioTab;