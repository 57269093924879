import React, { useEffect, useState } from "react";
import "./styles.scss";
import AudioIcon from "../../../../assets/images/project/audio.png";
import NoAudioIcon from "../../../../assets/images/project/no-audio.png";
import assetApi from "../../../../api/asset.api";

const SongsPlayer = () => {
    const [songs, setSongs] = useState([]); // Store the list of songs
    const [currentSongIndex, setCurrentSongIndex] = useState(0); // Track current song index
    const [audio, setAudio] = useState(null); // State to manage the Audio instance
    const [isPlaying, setIsPlaying] = useState(false); // Track if music is playing
    const [selectedSongId, setSelectedSongId] = useState(null);
    const [isInitialized, setIsInitialized] = useState(false); // Track first render

    // Toggle play/pause
    const togglePlay = () => {
        if (audio) {
            if (isPlaying) {
                audio.pause();
            } else {
                audio.play().catch((error) => {
                    console.error("Error playing audio:", error);
                });
            }
            setIsPlaying(!isPlaying);
        }
    };

    // Fetch the selected song ID
    useEffect(() => {
        const fetchSelectedSongId = async () => {
            const fetchedId = "671f5afa04cd703d3819a46d";
            setSelectedSongId(fetchedId);
        };

        fetchSelectedSongId();
    }, []);

    // Fetch the list of songs from API
    useEffect(() => {
        const fetchSongs = async () => {
            const data = {
                isOnlyNonDisable: true,
                limit: 1000,
                page: 1,
            };
            const response = await assetApi.getAllSongs(data);

            let orderedSongs;
            if (selectedSongId) {
                const selectedSong = response.results.find((song) => song.id === selectedSongId);
                orderedSongs = selectedSong
                    ? [selectedSong, ...response.results.filter((song) => song.id !== selectedSongId)]
                    : response.results;
            } else {
                orderedSongs = response.results;
            }

            setSongs(orderedSongs);
        };

        fetchSongs();
    }, [selectedSongId]);

    // Manage audio instance and playback on song index change
    useEffect(() => {
        const handleSongEnd = () => {
            setCurrentSongIndex((prevIndex) => (prevIndex + 1) % songs.length);
        };

        if (songs.length > 0) {
            // Cleanup previous audio instance if any
            if (audio) {
                audio.pause();
                audio.removeEventListener("ended", handleSongEnd);
            }

            const newAudioInstance = new Audio(
                `${process.env.REACT_APP_UPLOAD_ENDPOINT}/${songs[currentSongIndex]?.filePath}`
            );

            newAudioInstance.addEventListener("ended", handleSongEnd);
            setAudio(newAudioInstance);

            // Only play the audio automatically if it's the first render
            if (isInitialized && isPlaying) {
                newAudioInstance.play().catch((error) => {
                    console.error("Error playing audio:", error);
                });
            }

            return () => {
                newAudioInstance.pause();
                newAudioInstance.removeEventListener("ended", handleSongEnd);
            };
        }
    }, [currentSongIndex, songs]);

    // Automatically play music on the first render if `isPlaying` is true
    useEffect(() => {
        if (!isInitialized && songs.length > 0) {
            setIsInitialized(true);
            setIsPlaying(true);
        }
    }, [songs]);

    // Listen for play/pause toggle
    useEffect(() => {
        if (audio) {
            if (isPlaying) {
                audio.play().catch((error) => {
                    console.error("Error playing audio:", error);
                });
            } else {
                audio.pause();
            }
        }
    }, [isPlaying, audio]);

    return (
        <>
            <button className="btn-mute-background-music" onClick={togglePlay}>
                <img src={isPlaying ? AudioIcon : NoAudioIcon} alt="Toggle Play" />
            </button>
        </>
    );
};

export default SongsPlayer;
