import { Col, Row, Spin, notification } from "antd";
import { useEffect, useState } from "react";
import routesConstant from "../../../routes/routesConstant";
import { useLocation, useNavigate } from "react-router-dom";
import FacebookButton from "../../../components/facebookButton/FacebookButton";
import GoogleButton from "../../../components/googleButton/GoogleButton";
import {
  setStorageRefreshToken,
  setStorageToken,
  setStorageUserDetail,
} from "../../../utils/storage";
import {
  getAgreeWithTermAndConditions,
  setAgreeWithTermAndConditions,
  setUser,
} from "../../../redux/appSlice";
import { useDispatch, useSelector } from "react-redux";
import { decodeUrl, getDefaultHomePage } from "../../../utils/util";
import InstagramButton from "../../../components/instagramButton/InstagramButton";
import _ from "lodash";
import { APP_SOURCES, USER_ROLE } from "../../../utils/constants";
import LOGO from "../../../assets/images/LOGO.svg";
import authApi from "../../../api/auth.api";
import CarouselLoginAndRegister from "../../../components/carouselLoginAndRegister/CarouselLoginAndRegister";
import ReactGA from "react-ga4";
import "./rejister.css";
import BackGround1 from "../../../assets/images/login/bg-1.png";
import BackGround2 from "../../../assets/images/login/bg-2.png";
import BackGround3 from "../../../assets/images/login/bg-3.png";
import ModalTermsAndConditions from "../../../components/modalTermsAndConditions/ModalTermsAndConditions";
import global from "../../../redux/global";
import AuthBackground from "../../../assets/images/AuthBackground.png";
import appleLogo from "../../../assets/images/Apple.png";
import googleLogo from "../../../assets/images/googleLogo.png";
import whatsappLogo from "../../../assets/images/whatsapp_logo.png";
import LoginFooterImage1 from "../../../assets/images/login/LoginFooterImage1.png";
import LoginFooterImage2 from "../../../assets/images/login/LoginFooterImage2.png";
import LoginFooterImage3 from "../../../assets/images/login/LoginFooterImage3.png";
import LoginFooterImage4 from "../../../assets/images/login/LoginFooterImage4.png";
import LoginFooterImage5 from "../../../assets/images/login/LoginFooterImage5.png";
import LoginFooterImage6 from "../../../assets/images/login/LoginFooterImage6.png";
import { FiUser } from "react-icons/fi";
import { useFormik } from "formik";
import Cookies from 'js-cookie';
import * as Yup from "yup";
import { ring } from "ldrs";
import RetailerAuthFooter from "../../../components/retailerComponents/retailerAuthFooter/RetailerAuthFooter";
import { IoMdEyeOff, IoMdEye } from "react-icons/io";

const Register = () => {
  const location = useLocation();
  ring.register();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const [items, setItems] = useState([
    { id: 1, background: BackGround1 },
    { id: 2, background: BackGround2 },
    { id: 3, background: BackGround3 },
  ]);

  const [data, setData] = useState({
    utm_params: {
      utm_campaign: "",
      utm_medium: "",
      utm_source: ""
    },
    referral_info: {
      referrer_type: "",
      referring_url: ""
    }
  });



  const checkTrafficSource = () => {
    const urlParams = new URLSearchParams(location.search);
    const referrer = document.referrer;
    let sourceType = 'direct';
    let referringUrl = 'metadrob.com';
  
    // Handle referrer case
    if (referrer) {
      const referrerHost = new URL(referrer).hostname;
      if (['google.com', 'bing.com', 'yahoo.com'].some(domain => referrerHost.includes(domain))) {
        sourceType = 'organic';
        referringUrl = referrer;
      } else if (['facebook.com', 'twitter.com', 'linkedin.com'].some(domain => referrerHost.includes(domain))) {
        sourceType = 'organicsocial';
        referringUrl = referrer;
      } else {
        sourceType = 'referral';
        referringUrl = referrer;
      }
    }
  
    // Retrieve stored values from local storage
    const storedUtmParams = JSON.parse(localStorage.getItem("utm_params"));
    const storedReferralInfo = JSON.parse(localStorage.getItem("referral_info"));
  
    // New UTM Parameters
    const newUtmParams = {
      utm_campaign: urlParams.get("utm_campaign") || storedUtmParams?.utm_campaign || "not-provided",
      utm_medium: urlParams.get("utm_medium") || storedUtmParams?.utm_medium || "not-provided",
      utm_source: urlParams.get("utm_source") || storedUtmParams?.utm_source || "not-provided"
    };
  
    const newReferralInfo = {
      referrer_type: sourceType,
      referring_url: referringUrl || storedReferralInfo?.referring_url || "not-provided"
    };
  
    // Only update local storage if new values are found
    if (!storedUtmParams || urlParams.has("utm_campaign") || urlParams.has("utm_source") || urlParams.has("utm_medium")) {
      localStorage.setItem("utm_params", JSON.stringify(newUtmParams));
    }
    if (!storedReferralInfo || referrer) {
      localStorage.setItem("referral_info", JSON.stringify(newReferralInfo));
    }
  
    // Update state
    setData((prevData) => ({
      ...prevData,
      utm_params: newUtmParams,
      referral_info: newReferralInfo
    }));
  
    console.log("Referral Info:", newReferralInfo);
    console.log("UTM Params:", newUtmParams);
  };

 useEffect(() => {
  checkTrafficSource();

  // Retrieve from cookies
  const storedUtmParams = Cookies.get("utm_params");
  const storedReferralInfo = Cookies.get("referral_info");

  if (storedUtmParams && storedReferralInfo) {
    setData((prevData) => ({
      ...prevData,
      utm_params: JSON.parse(storedUtmParams),
      referral_info: JSON.parse(storedReferralInfo),
    }));
  }
}, [location.search]);

  const [isShowModalTermsAndConditions, setIsShowModalTermsAndConditions] =
    useState(false);
  const agreeWithTermAndConditions = useSelector(getAgreeWithTermAndConditions);

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email format") // Ensures the value is a valid email address
      .required("Email is required") // Makes the email field required
      .min(5, "Email must be at least 5 characters") // Minimum length check
      .max(50, "Email must be less than 50 characters") // Maximum length check
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Email format is incorrect"), // Custom pattern check
    password1: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters long.")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter.")
      .matches(/\d/, "Password must contain at least one number.")
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Password must contain at least one symbol."
      ),
    password2: Yup.string()
      .oneOf([Yup.ref("password1"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });
  useEffect(() => {
    // Reset the checkbox state when navigating to the page
    dispatch(setAgreeWithTermAndConditions(false));
  }, [dispatch]);
  const formik = useFormik({
    initialValues: {
      email: "",
      password1: "",
      password2: "",
      name: "",
    },
    validationSchema,
    onSubmit: () => {
      const returnUrl = new URLSearchParams(window.location.search).get(
        "returnUrl"
      );
      let role = USER_ROLE.RETAILERS;
      if (returnUrl) {
        role = USER_ROLE.CUSTOMER;
      }
      //Validate
      if (
        !formik.values.password1 ||
        !formik.values.name ||
        !formik.values.password2 ||
        !formik.values.email
      ) {
        notification.warning({
          message: "One or more fields is invalid.",
        });
        return;
      }
      if (formik.values.password1 !== formik.values.password2) {
        notification.warning({
          message: "Password must be the same.",
        });
        return;
      }
      if (!agreeWithTermAndConditions) {
        notification.warning({
          message: "Please agree with Terms and Conditions!",
        });
        return;
      }
      setLoading(true);
      let sendData = {
        email: formik.values.email,
        password: formik.values.password1,
        name: formik.values.name,
        role: role,
        appSource: global.IS_DROB_A ? APP_SOURCES.DROBA : APP_SOURCES.METADROB,
        utm_params:data?.utm_params,
        referral_info:data?.referral_info
      };
     
      authApi
        .register(sendData)
        .then((data) => {

          localStorage.removeItem("utm_params");
          localStorage.removeItem("referral_info");

          setLoading(false);
          if (returnUrl) {
            window.location = decodeUrl(returnUrl);
          } else {
            // notification.success({
            //   message: "Please check your email to activate your account!",
            // });
            navigate("/sent-email");
            // Navigate if needed
          }
        })
        .catch((err) => {
          notification.error({
            message: err?.response?.data.message || "Incorrect information",
          });
          setLoading(false);
        });
    },
  });

  const onNavigateToLogin = () => {
    const returnUrl = new URLSearchParams(window.location.search).get(
      "returnUrl"
    );
    if (returnUrl) {
      navigate(`${routesConstant.login.path}?returnUrl=${returnUrl}`, {
        replace: true,
      });
    } else {
      navigate(routesConstant.login.path, { replace: true });
    }
  };

  const onLoginSuccess = (data) => {
    if (global.IS_DROB_A) {
      if (
        data.user?.role !== USER_ROLE.ADMIN &&
        _.get(data, ["user", "appSource"]) === APP_SOURCES.METADROB
      ) {
        notification.warning({
          message: "Incorrect email or password",
        });
        return;
      }
    }

    if (!global.IS_DROB_A) {
      if (
        data.user?.role !== USER_ROLE.ADMIN &&
        _.get(data, ["user", "appSource"]) === APP_SOURCES.DROBA
      ) {
        notification.warning({
          message: "Incorrect email or password",
        });
        return;
      }
    }

    setStorageUserDetail(data.user);
    dispatch(setUser(data.user));
    setStorageToken(data.tokens.access.token);
    setStorageRefreshToken(data.tokens.refresh.token);

    notification.success({
      message: "Sign up successfully!",
    });
    const returnUrl = new URLSearchParams(window.location.search).get(
      "returnUrl"
    );
    if (returnUrl) {
      window.location = decodeUrl(returnUrl);
    } else {
      if (
        data.user?.role === USER_ROLE.RETAILERS &&
        !_.get(data, ["user", "isCompleteEnterProfile"], false)
      ) {
        navigate(routesConstant.firstLogin.path);
      } else {
        navigate(getDefaultHomePage(data.user));
      }
    }
  };

  const onShowTermsAndConditions = (e) => {
    e.stopPropagation();
    // window.alert('MYr')
    setIsShowModalTermsAndConditions(true);
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  return (
    <>
      {/* <div className="sm:px-[32px] md:px-[73px] min-h-screen flex justify-center items-center w-full relative"> */}
      <div className="sm:px-[32px] md:px-[73px] min-h-screen lg:min-h-[1080px] 3xl:min-h-screen  flex justify-center items-center lg:items-baseline 3xl:items-center pt-16  w-full relative">
        <div
          style={{
            backgroundImage: `url(${AuthBackground})`,
            backgroundSize: "cover",
          }}
          className={`login-image-wrapper`}
        >
          <div className="mx-auto pt-[7%]  sm:pb-0 sm:pl-10 sm:pt-10 w-full ">
            <img
              src={LOGO}
              alt=""
              className="h-8 mx-auto sm:mx-0 sm:h-[33px]"
            />
          </div>
          <div className="footer-login">
            <RetailerAuthFooter />
          </div>
        </div>

        <div className="w-full log-box h-[90vh] sm:h-auto sm:w-2/3 lg:w-2/5 bg-[#060606] rounded-[9px] sm:rounded-[16px] overflow-hidden absolute bottom-0 sm:relative z-10">
          <div className="flex min-h-full flex-col justify-center px-6 pt-4 sm:mb-4 lg:px-8">
            {/* <div className=" justify-end hidden sm:flex cursor-pointer" >
              <div className="flex rounded-md  w-fit" >
                <FiUser className="text-gray-500  pt-1" size={15} />
                <span className="text-gray-500 hover:text-slate-400 text-[11px]"> Continue as Guest</span>
              </div>
            </div> */}
            <div className="sm:mx-auto sm:w-full sm:max-w-sm ">
              <h2 className=" text-center text-2xl font-bold leading-9 tracking-tight text-white">
                Register Now
              </h2>
              <p className="text-center text-gray-300">
                Create your Metadrob account and unlock the tools to design and
                manage your 3D/Virtual Reality store.
              </p>
            </div>

            <div className="mt-10 sm:mx-auto w-full sm:w-11/12 ">
              <form className="" onSubmit={formik.handleSubmit}>
                <div className="sm:flex w-full gap-2">
                  <div className="w-full sm:w-1/2">
                    <label
                      for="name"
                      className="block text-sm font-medium leading-6 text-slate-200 text-left"
                    >
                      Name
                    </label>
                    <div className="mt-2 text-left"> 
                      <input
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Enter full name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="block w-full rounded-md border-0 py-2 bg-[#181818] text-slate-50 shadow-sm ring-1 ring-inset ring-[#4F4B4B] placeholder:font-normal placeholder:text-gray-400 focus:ring-2 focus:ring-inse sm:text-sm sm:leading-6 outline-none pl-3"
                        // className="block w-full rounded-[5px] bg-[#181818] py-1.5 text-white shadow-sm ring-1 ring-inset ring-[#4F4B4B] placeholder:text-[#7A7F84] focus:ring-2 focus:ring-inset"
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <span className="text-red-400 text-left text-[10px] font-light">
                          {formik.errors.name}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="w-full sm:w-1/2">
                    <label
                      for="email"
                      className="block text-sm mt-2 sm:mt-0 font-medium leading-6 text-slate-200 text-left"
                    >
                      Email
                    </label>
                    <div className="mt-2 text-left">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Enter your email address"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="block w-full rounded-md border-0 py-2 bg-[#181818] text-slate-50 shadow-sm ring-1 ring-inset ring-[#4F4B4B] placeholder:font-normal placeholder:text-gray-400 focus:ring-2 focus:ring-inse sm:text-sm sm:leading-6 outline-none pl-3"
                        // className="block w-full rounded-[5px] bg-[#181818] py-1.5 text-white shadow-sm ring-1 ring-inset ring-[#4F4B4B] placeholder:text-[#7A7F84] focus:ring-2 focus:ring-inset"
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <span className="text-red-400 text-left text-[10px] font-light">
                          {formik.errors.email}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="sm:flex gap-2 mt-2 sm:mt-3">
                  <div className="w-full sm:w-1/2">
                    <label
                      htmlFor="password1"
                      className="block text-sm font-medium leading-6 text-slate-200 text-left"
                    >
                      Password
                    </label>
                    <div className="mt-2 text-left relative">
                      <input
                        id="password1"
                        name="password1"
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter password"
                        value={formik.values.password1}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="block w-full pr-8 rounded-md border-0 py-2 bg-[#181818] text-slate-50 shadow-sm ring-1 ring-inset ring-[#4F4B4B] placeholder:font-normal placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 outline-none pl-3"
                      />
                      <div
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute right-3 top-2.5 cursor-pointer text-slate-200" // Adjusted styling if needed
                      >
                        {showPassword ? "Hide" : "Show"}
                      </div>
                      {formik.touched.password1 && formik.errors.password1 ? (
                        <span className="text-red-400 text-left text-[10px] font-light">
                          {formik.errors.password1}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="w-full sm:w-1/2">
                    <label
                      htmlFor="password2"
                      className="block text-sm mt-2 sm:mt-0 font-medium leading-6 text-slate-200 text-left"
                    >
                      Confirm Password
                    </label>
                    <div className="mt-2 text-left relative">
                      <input
                        id="password2"
                        name="password2"
                        type={showPassword2 ? "text" : "password"}
                        placeholder="Enter password"
                        value={formik.values.password2}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="block w-full pr-8 rounded-md border-0 py-2 bg-[#181818] text-slate-50 shadow-sm ring-1 ring-inset ring-[#4F4B4B] placeholder:font-normal placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 outline-none pl-3"
                      />
                      <div
                        onClick={() => setShowPassword2(!showPassword2)}
                        className="absolute right-3 top-2.5 cursor-pointer text-slate-200" // Adjusted styling if needed
                      >
                        {showPassword2 ? "Hide" : "Show"}
                      </div>
                      {formik.touched.password2 && formik.errors.password2 ? (
                        <span className="text-red-400 text-left text-[10px] font-light">
                          {formik.errors.password2}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="flex mt-3 items-center justify-center ">
                  <div className="flex justify-center">
                    <input
                      id="agreeWithTerms"
                      checked={agreeWithTermAndConditions}
                      onChange={() =>
                        dispatch(
                          setAgreeWithTermAndConditions(
                            !agreeWithTermAndConditions
                          )
                        )
                      }
                      type="checkbox"
                      className="h-3.5 w-3.5  text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-2 font-normal block text-[9px] text-gray-300"
                    >
                      I have read and agree{" "}
                      <span
                        className="cursor-pointer font-bold hover:text-slate-300"
                        onClick={(e) => onShowTermsAndConditions(e)}
                      >
                        Terms and Conditions{" "}
                      </span>
                      and{" "}
                      <span className="cursor-pointer font-bold  hover:text-slate-300">
                        Privacy Policy
                      </span>
                    </label>
                  </div>
                </div>

                <div className="mt-3">
                  <button
                    type="submit"
                    disabled={
                      loading ||
                      formik.values.email == "" ||
                      formik.values.name == "" ||
                      formik.values.password1 == "" ||
                      formik.values.password2 == ""
                    }
                    className={` ${
                      loading &&
                      "disabled:bg-slate-50 disabled:text-slate-950 flex justify-center"
                    } flex w-full  disabled:bg-[#383838] disabled:text-[#808080] sm:w-4/6 mx-auto justify-center rounded-md bg-slate-50 px-3 py-1.5 text-sm font-semibold leading-6 text-slate-950 shadow-sm hover:bg-slate-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                  >
                    {loading ? (
                      <div className="flex">
                        <l-ring
                          size="19"
                          stroke="2"
                          bg-opacity="0"
                          speed="2"
                          color="black"
                        ></l-ring>{" "}
                        <span className="ml-2">Loading </span>
                      </div>
                    ) : (
                      "Register"
                    )}
                  </button>
                </div>
              </form>
              {/* <div className="relative flex justify-center text-sm mt-3">
                <span className="px-2 text-gray-500">Or continue with</span>
              </div> */}
              <div className="mt-3">
                <span className="px-2 text-gray-500">Or continue with</span>
                <div className="flex mx-auto gap-4 justify-center my-2">
                  {/* <div className="object-fill ">
                    <img src={whatsappLogo} className="w-7 "></img>
                  </div>
                  <div className="object-fill ">
                    <img src={appleLogo} className="w-7 "></img>
                  </div> */}
                  <div className="object-fill ">
                    <GoogleButton
                      onSuccess={(data) => {
                        onLoginSuccess(data);
                      }}
                    />
                    {/* <img src={googleLogo} className="w-7"></img> */}
                  </div>
                </div>
              </div>

              {/* <div className=" justify-end sm:hidden my-2 cursor-pointer" >
                <div className="flex rounded-md  mx-auto w-fit" >
                  <FiUser className="text-gray-500  pt-1" size={15} />
                  <span className="text-gray-500 hover:text-slate-400 text-[11px]"> Continue as Guest</span>
                </div>
              </div> */}

              <p className="mt-3 text-center text-xs text-gray-500">
                Already have an account?
                <span
                  onClick={() => navigate("/login")}
                  href="#"
                  className="font-semibold leading-6 underline cursor-pointer text-slate-50 hover:text-slate-100"
                >
                  {" "}
                  Login.
                </span>
              </p>
            </div>
            <div className="mt-auto">
              <hr className="w-full opacity-20 mt-3" />
              <p className="text-xs text-center mt-2 lg:my-3 text-gray-600">
                The most innovative companies supported metadrob
              </p>

              <footer className="flex footer-com flex-col items-center text-centerx mt-auto sm:mt-0">
                <div className="container">
                  <div className="flex justify-evenly">
                    <div className="mb-6 lg:mb-0 flex">
                      <img
                        src={LoginFooterImage1}
                        className=" rounded-md w-10 md:w-16 object-contain"
                      />
                    </div>
                    <div className="mb-6 lg:mb-0 flex">
                      <img
                        src={LoginFooterImage2}
                        className=" rounded-md w-8 md:w-10 object-contain"
                      />
                    </div>
                    <div className="mb-6 lg:mb-0 flex">
                      <img
                        src={LoginFooterImage3}
                        className=" rounded-md w-9 md:w-12 object-contain"
                      />
                    </div>
                    <div className="mb-6 lg:mb-0 flex">
                      <img
                        src={LoginFooterImage4}
                        className=" rounded-md w-10 md:w-16 object-contain"
                      />
                    </div>
                    <div className="mb-6 lg:mb-0 flex ">
                      <img
                        src={LoginFooterImage5}
                        className=" rounded-md w-9 md:w-12  object-contain "
                      />
                    </div>
                    <div className="mb-6 lg:mb-0 flex">
                      <img
                        src={LoginFooterImage6}
                        className=" rounded-md w-9 md:w-12 object-contain "
                      />
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </div>
      </div>
      <ModalTermsAndConditions
        open={isShowModalTermsAndConditions}
        onClose={() => {
          setIsShowModalTermsAndConditions(false);
        }}
        // onAgree={() => {
        //     dispatch(setAgreeWithTermAndConditions(true))
        //     setIsShowModalTermsAndConditions(false)
        // }}
      />
    </>
  );
};

export default Register;
