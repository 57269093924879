import { Modal } from "antd";
import { useEffect, useRef, useState } from "react";
import "./styles.scss";
import closeIcon from "../../assets/images/project/close.svg";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import { useParams } from "react-router-dom";

const ModalProjectPublishConfirmation = (props) => {
  const {
    open,
    IsModalOpen,
    onOk = () => {},
    checkComponent,
  } = props;

  const projectNameRef = useRef();
  const timeOut = useRef();
  const [projectName, setProjectName] = useState();
  const { id } = useParams();

  const handleSave = async (e,modalOpenResponse) => {
    e?.preventDefault(); // Allows handling both Enter and click without needing a specific event
    try {
    //   const BASE_URL = process.env.REACT_APP_ENDPOINT;
    //   const response = await axios.put(`${BASE_URL}/project/project/${id}`, {
    //     name: projectName,
    //   });
    //   if (response.status === 200) {
        onOk(modalOpenResponse);
        IsModalOpen(false);
    //   }
    } catch (error) {
      console.error("Error updating project name:", error);
    }
  };

  useEffect(() => {
    if (open && checkComponent) {
      timeOut.current = setTimeout(() => {
        if (projectNameRef.current) {
          projectNameRef.current.focus();
        }
      }, 200);
    } else {
      timeOut.current = setTimeout(() => {
        const draftName = "Drafted"; // Get next draft name
        onOk(draftName);
      }, 200);
    }

    return () => {
      clearTimeout(timeOut.current);
    };
  }, [open]);

  const handleOk = () => {
    if (!projectName?.trim()) {
      const draftName = "Drafted";
      onOk(draftName);
    } else {
      handleSave();
    }
  };

  const handleModalClose = () => {
    IsModalOpen(false);
  };

  return (
    <>
      <Modal
        title={null}
        footer={null}
        open={open}
        closable={true}
        onCancel={handleModalClose}
        className="retailer-modal-project-publish-confirmation"
        closeIcon={
          <img src={closeIcon} alt="" onClick={handleModalClose} />
        }
        centered
      >
        <div className="text-project-name">Do you want to publish project!</div>
        {/* <div className="text-description mt-[6px]">
     
        </div> */}

        <div className="flex justify-center  mt-[34px] gap-3">
          <button
            className="btn-save"
            onClick={(e)=>handleSave(e,false)} // Handles button click
          >
            No
          </button>
          <button
            className="btn-save"
            onClick={(e)=>handleSave(e,true)} // Handles button click
          >
            Yes
          </button>
        </div>
      </Modal>
      
    </>
  );
};
export default ModalProjectPublishConfirmation;
